document.addEventListener('DOMContentLoaded', () => {
  const anchors = document.querySelectorAll('[data-smooth-scroll]');
  if (anchors.length === 0) {
    return;
  }

  const stickyBar = document.querySelector('[data-sticky-contact-detail]');

  anchors.forEach((el) => {
    el.addEventListener('click', function (e) {
      const target = document.querySelector(el.hash);

      if (target === null) {
        return;
      }

      e.preventDefault();
      try {
        if (stickyBar !== null) {
          const pos = target.style.position;
          const top = target.style.top;
          target.style.position = 'relative';
          target.style.top = '-' + stickyBar.clientHeight + 'px';
          target.scrollIntoView({ behavior: 'smooth' });
          target.style.top = top;
          target.style.position = pos;
        } else {
          target.scrollIntoView({ behavior: 'smooth' });
        }
      } catch (e) {
        console.debug('no element found with selector ' + el.hash);
      }
    });
  });
});
